@import '../../assets/variables.scss';

.PostJobForm{

    form{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        max-width: 960px;
        margin: 2em 2em;
    }
    label {
        margin-top: 2em;
    }
    input[type='text'] {
        @include input-big;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    input[type='checkbox']{
        display: flex;
        align-items: center;
        justify-content: center;
}
    button {
        @include purple-button;
        
    }
}