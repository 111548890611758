@import '../../assets/variables.scss';

h2 {
  display: block;
  text-align: center;
}

.RegistrationPage {
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  form {
    display: flex;
    flex-direction: column;
  }
}