@import '../../assets/variables.scss';

.hero {
    height: 100vh;
    background-image: url('../../assets/shanghai-2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30em;

    h2 {
      margin-top: 7em;
      color: rgba(0, 0, 0, .5);
      font-weight: 500;
      text-shadow: 1px 1px
    }

    h1 {
      color: $accent-color;
      font-size: 3em;
      font-family: 'Dancing Script', cursive;
      text-shadow: 2px 4px white;
    }
  }

  #logo-blue {
    height: 15vh;
    border-radius: 3px;
  }    


  #why-everest {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4em;

    h2 {
      font-size: 1.5em;
      color: $accent-color;
    }

    p {
      padding-top: 1em;
      font-size: 1.1em;
      margin: auto;
      color: $accent-color;
      width: 60%;
      text-align: center;

      &:last-child {
        line-height: 1.4em;
        color: black;
        text-align: justify;

      }
    }
  }

#why-china {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      padding-top: 2em;
      padding-bottom: 2em;
      font-size: 1.5em;
      color: $accent-color;
    }

    .cards-container {
      display: flex;
      flex-direction: column;
      margin: 0 8vw;
    }

    .description-card {
      background: white;
      overflow: hidden;
      width: 80%;
      margin: 10px auto;
      border: 1px solid grey;
      border: none;
      -webkit-box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.24);
      box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.24);
      border-radius: 10px;

      .description {
        margin: 1em 1em;

        h3 {
          padding-bottom: .5em;
        }
      }
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    img {
        height: 17vw;
        width: 28vw;
        border-radius: 10px; 
        margin: auto;
      }
     p {
       line-height: 1.4em;
     }
  }

  #start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 4em;

    h3 {
      font-size: 1.8em;
      color: $accent-color;
    }

    a {
      @include purple-button;
    }
  }

@media screen and (min-width: 800px) {
    .hero {
      height: 100vh;
      background-position: right;

      h2 {
        font-weight: 500;
        font-size: 1.8em;
      }

      h1 {
        font-size: 5em;
      }
    }

      #logo-blue {
        height: 20vh;
  }    

    #why-everest h2 {
      font-size: 2em;
    }

    #why-china h2 {
      font-size: 2em;
    }

    h3 {
      text-align: center;
    }
    
    #why-china .cards-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 8vw;

      .description-card {
        width: 28%;
    }
    }
  }