@import '../../assets/variables.scss';

.NavBar {
  background: RGBA(65, 85, 145, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2vw;
  height: 3.4em;
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 1px solid $accent-color;

  .active {
    color: $accent-color;
  }

  #logo {
    height: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 1.5px;
  }

  a {
    color: rgb(245, 245, 245);
    text-decoration: none;
  }

  .nav-links {
    display: flex;
    list-style-type: none;
    align-items: center;

    .highlighted-btn {
      @include border-button;
    }

    a {
      margin: 0 0.25em;
      font-size: 0.8em;
      color: white;
      font-weight: bolder;
    }
  }
}

@media screen and (min-width: 600px) {

  .NavBar .nav-links li a {
    font-size: 1em;
    margin: 0 1em;
  }

  .NavBar {
    #logo {
        height: 2em;
        width: auto;
      }
  }

  

}