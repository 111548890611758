$accent-color: #415591;
$accent-red: #BA5085;
$accent-brown: #D4B79F;
$white: rgb(240, 240, 240);
$black: black;
$nav-bar-height: 3.4em;
$border-radius-small: 10px;
$border-radius-medium: 20px;
$border-radius-large: 40px;
$grey-border: #aaa;
$fancy-font: 'Source Sans Pro', sans-serif;
;

@mixin box-shadow-reg {
  -webkit-box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.24);
  box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.24);
}


@mixin border-button {
  
  color: white;
  border: 2px solid $accent-red;
  border-radius: 10px;
  padding: .5em 1em;
  cursor: pointer;
  &:focus {
    outline-width: 0;
  }
  &:hover {
    background: white;
    color: $accent-color;
  }
}

@mixin purple-button($color: $accent-color) {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  padding: .5em 1em;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  background: $color;
  color: white;
  text-decoration: none;
  margin: 1em;
  -webkit-box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.6);
  box-shadow: -1px 17px 35px -8px rgba(0, 0, 0, 0.6);
  transition-duration: 1.2s;
  cursor: pointer;
&:focus {
    outline-width: 0;
  }
  &:hover {
    transform: translate(4px, 6px);
    background: white;
    color: $accent-color;
    box-shadow: none;
    border: 1px solid $accent-color;
  }
}

@mixin input-big {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: 'Source Sans Pro', sans-serif;

  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

@mixin underline-input {
    border: none;
    border-bottom: 2px solid $accent-color;
    height: 2em;
    font-size: 1em;
    font-family: $fancy-font;
    &:focus {
      outline-width: 0;
    }
}