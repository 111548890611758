@import '../../assets/variables.scss';

.Application {
    margin-top: 5em;
    h2 {
        padding: 1em;
        margin-bottom: 1em;
        text-align: center;
        }
    
    .application {
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .profile {
        padding: 1em;
        width: 90%;
        text-align: left;
        background: $white;
        margin: auto;
        @include box-shadow-reg;
        h3 {
            padding: 1em 0;
        }
        div {
            padding: 0.2em 0;
        }
    }
    
    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        button {
            margin: 2em;
            @include purple-button;
    }
    }
}

@media screen and (min-width: 600px) {
    .Application {

        .application {
            flex-direction: row;
        }

        .profile {
            width: 35%;
        }
    }
}