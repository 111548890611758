@import '../../assets/variables.scss';



jobsListPage {
    * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
    color: #444;
    text-align: center;
    background: rgb(224, 224, 224);
  }

  header {
    background: rgb(177, 177, 177);
    padding: 1em 0;
  }

  h1 {
    font-size: 1.4em;
    font-weight: bold;
    margin: 2em 0;
  }

  h2 {
    font-size: 1.2em;
    font-weight: bold;
  }

  .job-container {
      background: rgb(177, 177, 177);
      width: 80%;
      margin-left: auto;
      margin-right: auto;
  }
}

  .no-job {
    text-align: center;
    color: red;
    font-size: 2em;
  }