@import '../../assets/variables.scss';


.edit-teacher {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    input,
    textarea,
    select {
      @include input-big;
    }

    input,
    select,
    textarea {
      margin-top: 0.5em;
    }

    select {
        padding: 0;
    }

    label {
        margin-top: 1em;
        font-weight: bolder;
        color: $accent-color;
    }

    button {
        @include purple-button;
        width: 200px;
      }
}