@import '../../assets/variables.scss';

.ApplicationsPage {
    margin: 5em 2em;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;


    h2 {
    margin-bottom: 1em;
    }
}

.ApplicationsPage {
    .ApplicationCardsList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

}

    .ApplicationCard {
        background: $white;
        margin: 2em 0em;
        @include border-button;
    }

    .ApplicationCard a {
        line-height: 1.2em;
        text-decoration: none;
        color: black;
    }

}