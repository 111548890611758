@import '../../assets/variables.scss';

.EditProfilePage {
    margin: 5em 0;
    
    h2 {
        text-align: center;
        margin-bottom: 1em;

    }
    
}