@import '../../assets/variables.scss';

.EditAdminForm {

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
    height: 100vh;

    input,
    textarea {
      @include input-big;
    }

    textarea {
      width: 50%;
      height: 15em;
    }

    input,
    select,
    textarea {
      margin-top: 0.5em;
    }

    label {
      margin-top: 1em;
    }

    button {
      @include purple-button;
    }
  }
}