@import '../../assets/variables.scss';

.AboutPage {
    margin-top: 5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    text-align: center;
    h2 {
    margin-bottom: 1em;
    }

    #About {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.5em;
    }

    .about-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        margin: 1.2em auto;
        text-align: left;
    }

    #about-logo {
        width: 70%;
        margin: 1em auto;
    }

    #about-fesco {
        
        img {
            display: flex;
            margin: auto;
        }
    }

    #about-kejibu {
        width: 80%;
        margin: 1em auto;
    }

    #about-hurun {
        width: 70%;
        margin: 1em auto;
    }
}

@media screen and (min-width: 768px) {
    .AboutPage {
    
        #About div {
            width: 60%;
        }
        
    #about-logo {
        width: 40%;
        margin: 1em auto;
    }

    #about-kejibu {
        width: 70%;
        margin: 1em auto;
    }

    #about-hurun {
        width: 60%;
        margin: 1em auto;
    }
    }
}

// @media screen and (min-width: 2200px) {
//     #staff {
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         width: 40%;
//     }
//     #staff_overview {
//         width: 40%;
//     }
//     .staff_member {
//     padding: 1.2em;
//     width: calc(100% / 3);
// }
// }

// @media screen and (max-width: 1600px) {
//     #staff {
//         display: flex;
//         flex-direction: column;
//     }
//     .staff_member {
//         width: 100%;
//         img {
//             width: calc(100% / 3);
//         }
//     }
// }

// @media screen and (max-width: 800px) {
//     #staff {
//         display: flex;
//         flex-direction: column;
//         width: 80%;
//     }
//     #staff_overview {
//         width: 80%;
//     }
//     .staff_member {
//         width: 100%;
//         img {
//             width: calc(100% / 3);
//         }
//     }
// }