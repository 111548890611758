@import '../../assets/variables.scss';

.LoginForm {
  padding-bottom: 1.2em;

  legend {
    margin-bottom: .5em;
    color: $accent-color;
  }

  input {
    border: none;
    border-bottom: 2px solid $accent-color;
    height: 2em;
    font-size: 1em;
    font-family: $fancy-font;
    &:focus {
      outline-width: 0;
    }
  }

  .username, .password {
    label {
      margin-top: 2em;
      padding: 0 2em;
      color: grey;
    }
    
    input {
      margin: 0 2em;
    }

    display: flex;
    flex-direction: column;
  }

  #login-button {
    @include purple-button;
    width: 82px;
    height: 34px;
   margin: 1.5em auto;

  }

  #demo {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
    .demo-button {
      background: gray;
      opacity: 0.5; 
      font-size: 0.6em; 
      border-radius: 0;
      margin: 0px;
    }

    .demo-button:hover {
      transform: translate(0px, 0px);
      background: black;
      color: white;
    }
  }
  
  #admin-login {
    text-align: center;
    color: lightgray;
  }

  #admin-input {
    vertical-align: middle;
  }
}