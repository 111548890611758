@import '../../assets/variables.scss';


.filter-form {
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  }

.form-group {
  width: 70%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  label {
    margin-right: 10px;
  }
}

.form-input {
  input {
    @include underline-input;
  }
}

.form-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96%;
}

button {
      @include purple-button;
      margin: 1.5em auto;
      display: block;
    }

@media screen and (min-width:600px) {
  .form-section { flex-wrap: wrap;  }

  .filter-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 70%;
    margin: 5em auto;
  }

  .form-group {
    justify-content: flex-end;
    width: calc(50% - 40px);
    display: flex;
    margin: 15px 0;
      &:nth-child(2n) {
      margin-left: 20px;
      justify-content: flex-start;
    }
}
}

    