@import '../../assets/variables.scss';

.loader {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 16px solid $accent-color; /* Purple */
  border-bottom: 16px solid $accent-color;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: auto;
  margin-bottom: 2em;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}