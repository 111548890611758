@import '../src/assets/variables.scss';

* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  /* background: rgba(212, 192, 175, 0.459); */
  /* background: #F6FCF9; */
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Sans Pro', sans-serif;
}

h1 {
  font-weight: bolder;
  font-size: 1.4em;
}
h2 {
  font-weight: bolder;
  font-size: 1.2em;
}
h3 {
  font-weight: bolder;
  font-size: 1.1em;
}

.red {
  color: red;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: $accent-brown;
  height: 10vh;
  position: relative;
  bottom: 0px;
  width: 100%;
}