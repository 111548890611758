@import '../../assets/variables.scss';

.TeacherProfile {
    margin: 5em 0;
    height: 100vh;

    ul {
        padding: 3em;
    }

    li {
        color: $accent-color;
        line-height: 1.5em;
        font-size: 1.5em;
    }

    span {
        color: black;
    }
}


@media screen and (min-width: 600px) {
    .TeacherProfile {
        ul {
            margin-left: 30%;
        }
    }
}