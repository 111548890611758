@import '../../assets/variables.scss';

.AdminProfile {
  height: 100vh;
  margin: 5em 0;
  padding: 1em;
  display: flex;
  flex-direction: column;
  text-align: center;

  h2 {
    margin-bottom: 1em;
    color: $accent-red;
  }
  div {
    width: 80%;
    background: $white;
    margin: 0 auto;
    @include box-shadow-reg; 
  }
  ul, p {
    margin: 2em 0em;
    height: 50%;
  }
  li {
    line-height: 1.2em;
  }
  a {

    @include purple-button;
  };

}