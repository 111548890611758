@import '../../assets/variables.scss';

.LoginPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  h2 {
    margin: 1em 0;
    padding: 0 2em;
  }
  p {
    margin: 1em 0;
    padding: 0 2em;
  }

  .login-box {
      border-radius: $border-radius-small;
      @include box-shadow-reg;
  }
}