@import '../../assets/variables.scss';

.teacher-registration {
    max-width: 500px;
    margin: 40px auto;
    border: 1px solid #d4d4d4;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 2px 5px 10px #cccccc;
}

label {
    margin-top: 1em;
    margin-bottom: 0.4em;
}

input, select {
    height: 2em;
}